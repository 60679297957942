/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import VectorMap from "views/maps/VectorMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import ReactTables from "views/tables/ReactTables.js";
import RegularTables from "views/tables/RegularTables.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import Wizard from "views/forms/Wizard.js";
import ValidationForms from "views/forms/ValidationForms.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import RegularForms from "views/forms/RegularForms.js";
import Calendar from "views/Calendar.js";
import Widgets from "views/Widgets.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import Buttons from "views/components/Buttons.js";
import SweetAlert from "views/components/SweetAlert.js";
import Notifications from "views/components/Notifications.js";
import Grid from "views/components/Grid.js";
import Typography from "views/components/Typography.js";
import Panels from "views/components/Panels.js";
import Icons from "views/components/Icons.js";
import Pricing from "views/pages/Pricing.js";
import Register from "views/pages/Register.js";
import Timeline from "views/pages/Timeline.js";
import User from "views/pages/User.js";
import Login from "views/pages/Login.js";
import Rtl from "views/pages/Rtl.js";
import Lock from "views/pages/Lock.js";
import ChaptersTable from "new/ChaptersTable";
import NewChapterForm from "new/NewChapterForm";
import SectionsTable from "new/SectionsTable";
import NewSectionForm from "new/NewSectionForm";
import UsersTable from "new/UsersTable";
import NewUserForm from "new/NewUserForm";
import NewPriceForm from "new/NewPriceForm";
import NewMessageForm from "new/NewMessageForm";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/chapters",
    name: "Chapters",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-map-big",
    component: <ChaptersTable />,
    layout: "/admin",
  },
  {
    path: "/new_chapter",
    name: "New Chapter",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-map-big",
    component: <NewChapterForm />,
    layout: "/admin",
  },
  {
    path: "/sections",
    name: "Sections",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bullet-list-67",
    component: <SectionsTable />,
    layout: "/admin",
  },
  {
    path: "/new_section",
    name: "New Section",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bullet-list-67",
    component: <NewSectionForm />,
    layout: "/admin",
  },
  {
    path: "/add_user",
    name: "Add User",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <NewUserForm />,
    layout: "/admin",
  },
  {
    path: "/set_price",
    name: "Set Price",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-coins",
    component: <NewPriceForm />,
    layout: "/admin"
  },
  {
    path: "/bulk_msg",
    name: "Bulk Message",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <NewMessageForm />,
    layout: "/admin"
  },
];

export default routes;

/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const NewUserForm = () => {
  const [username, setUsername] = useState('')
  const [alert, setAlert] = React.useState(null);
  const onUsernameType = (event) => {
    setUsername(event.target.value)
  }
  const hideAlert = () => {
    setAlert(null);
  };
  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="New User Added!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };
  const onUserSubmit = () => {
    let newUserName = username.replace('@', '')
    setUsername(newUserName)
    console.log(username)
    axios.post('https://scambot.levothyroxine.uno/api/users', { username: newUserName }, { headers: { Authorization: `Bearer ${localStorage.getItem('jwt-token')}` } })
      .then((response) => successAlert())
    setUsername('')
  }
  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">New User</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <label>Username</label>
                  <FormGroup>
                    <Input type="text" value={username} onChange={onUsernameType} />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={onUserSubmit}>
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewUserForm;

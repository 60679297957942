/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from 'axios';
import { Navigate } from "react-router-dom";

import ReactTable from "components/ReactTable/ReactTable-backup";

const UsersTable = () => {
  const [data, setData] = React.useState([])
  useEffect(()=> {
    axios.get('https://scambot.levothyroxine.uno/api/users', {headers:{'Authorization': `Bearer ${localStorage.getItem('jwt-token')}`}})
    .then(response => {
      let usersData = response.data.map(
        user => {
          return {
            id: user._id,
            name: user.name,
            username: '@'+user.username,
            telegramId: user.telegram_id
          };
        })
      setData(usersData)
    })
  }, [])

  return (
    <>
      <div className="content">
        <Col md={8} className="ml-auto mr-auto">
          <h2 className="text-center">Users</h2>
        </Col>
        <Row className="mt-5">
        
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Users list</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "username",
                      accessor: "username",
                      
                    },
                    {
                      Header: "Telegram ID",
                      accessor: "telegramId",
                      sortable: false,
                    }
                  ]}
                  defaultPageSize={30}
                  useFilters={false}
                  usePagination={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UsersTable;

/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from 'axios';
import { Navigate } from "react-router-dom";

import ReactTable from "components/ReactTable/ReactTable.js";

const SectionsTable = () => {
  const [data, setData] = React.useState([])
  const [toNewSection, setToNewSection] = React.useState(false)
  useEffect(()=> {
    axios.get('https://scambot.levothyroxine.uno/api/sections', {headers:{'Authorization': `Bearer ${localStorage.getItem('jwt-token')}`}})
    .then(response => {
      let sectionData = response.data.map(
        section => {
          return {
            id: section._id,
            title: section.title,
            description: section.description,
            chapter_name: section.chapter_name,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                {" "}
                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    let obj = data.find((o) => o.id === section._id);
                    alert(
                      obj.title
                    );
                  }}
                  color="warning"
                  size="sm"
                  className={classNames("btn-icon btn-link like")}
                >
                  <i className="tim-icons icon-pencil" />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                  onClick={() => {
                    var newdata = response.data;
                    console.log(response.data)
                    newdata.find((o, i) => {
                      if (o._id === section._id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        
                        response.data.splice(i, 1);
                        return true;
                      }
                      return false;
                    });
                    console.log(newdata)
                    // setData  (newdata);
                    // axios.delete(`https://scambot.levothyroxine.uno/api/chapters/${chapter._id}`, {headers:{'Authorization': `Bearer ${localStorage.getItem('jwt-token')}`}})
                  }}
                  color="danger"
                  size="sm"
                  className={classNames("btn-icon btn-link like")}
                >
                  <i className="tim-icons icon-simple-remove" />
                </Button>{" "}
              </div>
            ),
          };
        })
      setData(sectionData)
    })
  }, [])
  if (toNewSection){
    return <Navigate to="/admin/new_section" />;
  }
  return (
    <>
      <div className="content">
        <Col md={8} className="ml-auto mr-auto">
          <h2 className="text-center">Sections</h2>
        </Col>
        <Row className="mt-5">
        
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Sections list</CardTitle>
              </CardHeader>
              <Button color="primary" size="lg" onClick={() => setToNewSection(true)}>
                  Add Section
              </Button>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "title",
                    },
                    {
                      Header: "Description",
                      accessor: "description",
                      
                    },
                    {
                        Header: "Chapter Name",
                        accessor: "chapter_name"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    }
                  ]}
                  defaultPageSize={30}
                  useFilters={false}
                  usePagination={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SectionsTable;

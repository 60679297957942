/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { FileUpload }  from 'primereact/fileupload';

const NewSectionForm = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [chapter_id, setChapterId] = useState('')
    const [alert, setAlert] = React.useState(null);
    const [singleSelect, setsingleSelect] = React.useState(null);
    const [chapterList, setChapterList] = React.useState([])
    const uploaderRef = useRef()
    const [uploading, setUploading] = React.useState(false)
    useEffect(() => {
        axios.get('https://scambot.levothyroxine.uno/api/chapters', { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } })
            .then((response) => {
                setChapterList(response.data.map(chapter => { return { value: chapter._id, label: chapter.title } }))
            })
    }, [])
    const onTitleType = (event) => {
        setTitle(event.target.value)
        console.log(title)
    }
    const onDescriptionType = (event) => {
        setDescription(event.target.value)
    }
    const hideAlert = () => {
        setAlert(null);
    };

    const successAlert = () => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="New Chapter Added!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="success"
                btnSize=""
            >
            </ReactBSAlert>
        );
    };
    const onSectionSubmit = () => {
        let files = uploaderRef.current.getFiles()
        setUploading(true)
        const formData = new FormData();
        formData.append("file", files[0]);
        axios.post(
            'https://scambot.levothyroxine.uno/api/create-file',
            formData,
            { headers: { Authorization: `Bearer ${localStorage.getItem('jwt-token')}`}}
        )
        .then(response => {
            console.log(response.data)
            axios.post(
                "https://scambot.levothyroxine.uno/api/sections",
                {
                    title: title,
                    description: description,
                    file_id: response.data.file_id,
                    vod_id: response.data.vod_id,
                    chapter_id: singleSelect.value
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('jwt-token')}` } }
            ).then(
                (response) => {
                    console.log(response)
                    successAlert()
                    setUploading(false)
                    setTitle('')
                    setDescription('')
                    uploaderRef.current.clear()
                }
            )
        })
    }
    const handleUploadStart = (event) => {
        setUploading(true)
        console.log(uploading)
    }
    const handleUploadComplete = (event) => {
        console.log(event)
    }
    return (
        <>
            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">New Section</CardTitle>
                                
                            </CardHeader>
                            <CardBody>
                                <Form action="#">
                                    <label>Title</label>
                                    <FormGroup>
                                        <Input type="text" value={title} onChange={onTitleType} />
                                    </FormGroup>
                                    <label>Description</label>
                                    <FormGroup>
                                        <Input type="text" value={description} autoComplete="off" onChange={onDescriptionType} />
                                    </FormGroup>
                                    <label>Chapter Name</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select info"
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            value={singleSelect}
                                            onChange={(value) => setsingleSelect(value)}
                                            options={chapterList}
                                            placeholder="Single Select"
                                        />
                                    </FormGroup>
                                    <label>Video File</label>
                                    <FormGroup>
                                    <FileUpload mode="basic" ref={uploaderRef} name="file" accept="video/*" maxFileSize={1000000000} onUpload={handleUploadComplete} onBeforeUpload={handleUploadStart}/>
                                    </FormGroup>
                                </Form>
                                {uploading &&
                                 <div>Uploading...</div>
                                }
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={onSectionSubmit}>
                                    Submit
                                </Button>
                                
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default NewSectionForm;

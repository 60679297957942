/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const NewPriceForm = () => {
    const [price, setPrice] = useState(0)
    const [discountedPrice, setDiscountedPrice] = useState(0)
    const [remainingDiscounts, setRemainingDiscounts] = useState(0)
    const [alert, setAlert] = React.useState(null);
    const onPriceType = (event) => {
        setPrice(event.target.value)
    }
    const onDiscountedPriceType = (event) => {
        setDiscountedPrice(event.target.value)
    }
    const onRemainingDiscountsType = (event) => {
        setRemainingDiscounts(event.target.value)
    }
    const hideAlert = () => {
        setAlert(null);
    };
    const successAlert = () => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="New Price set!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="success"
                btnSize=""
            >
            </ReactBSAlert>
        );
    };
    useEffect(() => {
        axios.get('https://scambot.levothyroxine.uno/api/price', { headers: { Authorization: `Bearer ${localStorage.getItem('jwt-token')}` } })
        .then(
                response => {
                    setPrice(response.data.price)
                    setDiscountedPrice(response.data.discounted_price)
                    setRemainingDiscounts(response.data.remaining)
    })}, [])

    const onPriceSubmit = () => {
        axios.post('https://scambot.levothyroxine.uno/api/price', { price: price, discounted_price: discountedPrice, remaining_discounts: remainingDiscounts }, { headers: { Authorization: `Bearer ${localStorage.getItem('jwt-token')}` } })
            .then((response) => successAlert())
    }
    return (
        <>
            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">New Price</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form action="#">
                                    <label>Price</label>
                                    <FormGroup>
                                        <Input type="text" value={price} onChange={onPriceType} />
                                    </FormGroup>
                                    <label>Discounted Price</label>
                                    <FormGroup>
                                        <Input type="text" value={discountedPrice} onChange={onDiscountedPriceType} />
                                    </FormGroup>
                                    <label>Remaining Discounts</label>
                                    <FormGroup>
                                        <Input type="text" value={remainingDiscounts} onChange={onRemainingDiscountsType} />
                                    </FormGroup>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" type="submit" onClick={onPriceSubmit}>
                                    Submit
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default NewPriceForm;

/*!

=========================================================
* Black Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

const NewMessageForm = () => {
    const [message, setMessage] = useState('')
    const [alert, setAlert] = React.useState(null);
    const onMessageType = (event) => {
        setMessage(event.target.value)
    }
    const hideAlert = () => {
      setAlert(null);
    };
    const successAlert = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="New Message Sent!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
        </ReactBSAlert>
      );
    };
    const onMessageSubmit = () => {
        axios.post('https://scambot.levothyroxine.uno/api/message', {message: message}, {headers:{Authorization:`Bearer ${localStorage.getItem('jwt-token')}`}})
        .then((response) => successAlert())
      setMessage('')
    }
  return (
    <>
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">New Message</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <label>Message</label>
                  <FormGroup>
                    <Input type="textarea" value={message} onChange={onMessageType} aria-multiline={true}/>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={onMessageSubmit}>
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewMessageForm;
